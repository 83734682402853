export const DEFAULT_LANGUAGE = 'en_US'
export const LANGUAGE_SEPARATOR = '_'
export const LOCALE_ISO_LENGTH = 5
export const LANGUAGE_TAG_REGEXP = /^[a-z]{2}_[A-Z]{2}/g

const LANGUAGE_NAMES = {
  en_US: 'English',
  ru_RU: 'Русский',
  es_ES: 'Español',
  uk_UA: 'Українська',
  uz_UZ: 'Uzbek',
  pt_PT: 'Português',
  hu_HU: 'Magyar',
  de_DE: 'Deutsch',
  pl_PL: 'Polski',
  it_IT: 'Italiano',
  fi_FI: 'Suomi',
  cs_CZ: 'Čeština',
  sk_SK: 'Slovenčina',

}

const DEFAULT_LANGUAGE_LIST = [
  'en_US',
  'es_ES',
  'ru_RU',
  'uk_UA',
  'uz_UZ',
  'pt_PT',
  'hu_HU',
  'de_DE',
  'pl_PL',
  'it_IT',
  'fi_FI',
  'cs_CZ',
  'sk_SK',
]

export {
  LANGUAGE_NAMES,
  DEFAULT_LANGUAGE_LIST,
}
