import React from 'react'

import clsx from 'clsx'

import {
  Grid,
  Box,
  InputAdornment,
  Typography,
  Alert,
  Button,
} from '@coinspaid-hub/ui-kit'
import {
  TextField,
} from '@coinspaid-hub/ui-kit/src/lab'
import CurrencyIcon from '@processing/currency-icons'
import { CURRENCIES } from 'constants/currencies'

import InputWithCopy from 'components/InputWithCopy'
import QrCodeWithIcon from 'components/QrCodeWithIcon'
import Trans from 'components/Trans'

import { COPIED_TEXTS } from './constants'
import styles from './styles'

export default function template(props, state) {
  const { data } = props

  const {
    merchant_name,
    qrcode,
    currency,
    address,
    amount,
    tag,
    min_deposit,
    url_back,
  } = data
  const {
    customMinDeposit,
  } = state

  const classes = styles()

  const minDeposit = customMinDeposit?.[props.clientId]?.[currency] || min_deposit
  const currencyName = CURRENCIES[currency]?.name || currency

  return (
    <Grid
      container
      direction='column'
      className={classes.body}
    >
      {qrcode && (
        <Box className={clsx(classes.greyArea, !merchant_name && classes.borderRadius)}>
          <QrCodeWithIcon
            currencyIso={currency}
            qrcode={qrcode}
          />
        </Box>
      )}
      <Grid
        container
        direction='column'
        className={classes.form}
      >
        <Typography
          variant='subtitle3'
          dataTestId='body-description'
        >
          <Trans
            i18nKey='forms:body.description'
            defaults='Send the desired amount using the address or QR code, and your balance will be topped up'
          />
        </Typography>
        <Alert
          color='warning'
          titleText={(
            <Trans
              i18nKey='views:body.warning.title'
              defaults='Pay attention'
            />
          )}
          sx={{ mt: 3 }}
        >
          <ul
            className={classes.alertList}
          >
            <li>
              <Typography
                variant='caption3'
                dataTestId='body-alert-text'
              >
                <Trans
                  i18nKey='views:body.warning.text'
                  defaults='Sending the amount below <bold>{{minDeposit}} {{currency}}</bold> or any other currency will result in loss of funds'
                  values={{
                    minDeposit,
                    currency,
                  }}
                  components={{
                    bold: (
                      <Typography
                        variant='caption'
                        className={classes.textBold}
                      />
                    ),
                  }}
                />
              </Typography>
            </li>
            <li>
              <Typography
                variant='caption3'
                dataTestId='body-alert-text'
              >
                <Trans
                  i18nKey='body.warning.text.wrong_currency'
                  defaults='Sending any other currencies will result in a loss of funds as well'
                />
              </Typography>
            </li>
          </ul>
        </Alert>
        <Grid
          container
          className={classes.fieldWrapper}
        >
          <InputWithCopy
            dataTestId='field-address'
            className={classes.fieldClass}
            value={address}
            type='address'
            label={(
              <Trans
                i18nKey='forms:fields.address'
                defaults='Address'
              />
            )}
            snackBarMessages={COPIED_TEXTS}
          />
        </Grid>
        {tag && (
          <Grid
            container
            className={classes.fieldWrapper}
          >
            <InputWithCopy
              dataTestId='field-tag'
              className={classes.fieldClass}
              value={tag}
              type='tag'
              label={(
                <Trans
                  i18nKey='forms:fields.tag'
                  defaults='Tag'
                />
              )}
              snackBarMessages={COPIED_TEXTS}
            />
          </Grid>
        )}
        <Grid
          container
          className={classes.fieldWrapper}
        >
          <TextField
            className={classes.fieldClass}
            fullWidth
            readOnly
            margin='dense'
            variant='outlined'
            value={currencyName}
            label={(
              <Trans
                i18nKey='forms:fields.currency'
                defaults='Currency'
              />
            )}
            startAdornment={(
              <InputAdornment
                position='start'
                className={classes.icon}
              >
                <CurrencyIcon currency={currency} />
              </InputAdornment>
            )}
          />
        </Grid>
        {amount && (
          <Grid
            container
            className={classes.fieldWrapper}
          >
            <TextField
              className={classes.fieldClass}
              fullWidth
              readOnly
              value={`${amount} ${currency}`}
              label={(
                <Trans
                  i18nKey='forms:fields.amount'
                  defaults='Amount'
                />
              )}
            />
          </Grid>
        )}
        <Grid
          container
          justifyContent='center'
          className={classes.buttonWrapper}
        >
          <a href={url_back}>
            <Button
              dataTestId='go-back-link'
              variant='text'
            >
              <Trans
                i18nKey='forms:buttons.go_back'
                defaults='Go back'
              />
            </Button>
          </a>
        </Grid>
      </Grid>
    </Grid>
  )
}
